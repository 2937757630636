<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="filteredData"
      :loading="isLoading"
      loading-text="Carregando dados..."
    >
      <template
        v-slot:no-data
      >
        <span>Sem informações para exibir</span>
      </template>

      <template #[`item.show_document`]="{item}">
        <v-icon
          v-if="item.files_id === isLoadingFile ? false : true "
          medium
          class="me-2"
          color="warning"
          @click="showFileInBrowser(item.files_id)"
        >
          {{ icons.mdiEye }}
        </v-icon>

        <v-progress-circular
          v-else
          color="warning"
          indeterminate
        />
      </template>
    </v-data-table>
    <v-card-text>
    </v-card-text>
  </v-card>
</template>

<script>

import axiosIns from '@/plugins/axios'
import {
  mdiDelete, mdiEye, mdiPencil, mdiPlaylistPlus,
} from '@mdi/js'

export default {
  name: 'CirType',
  props: {
    search: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'IDENTIFICAÇÃO', value: 'identify', sortable: true, align: 'center',
        },
        {
          text: 'DOCUMENTO', value: 'documents_description', sortable: false, align: 'center',
        },
        {
          text: 'VISUALIZAR DOCUMENTO', value: 'show_document', sortable: false, align: 'center',
        },
      ],

      tableData: [],
      isLoading: false,
      isLoadingFile: false,

      icons: {
        mdiPlaylistPlus,
        mdiPencil,
        mdiDelete,
        mdiEye,
      },
    }
  },

  computed: {
    filteredData() {
      if (!this.search) return this.tableData

      const searchTerm = this.search.toLowerCase()

      return this.tableData.filter(item => (item.identify?.toLowerCase().includes(searchTerm)
        || item.documents_description?.toLowerCase().includes(searchTerm)))
    },
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns.get('api/v1/sgq/file/view_docs').then(
        response => {
          const { data } = response.data

          const arrayCompleted = data.filter(key => key.type_name?.toUpperCase() === 'CIR')

          this.tableData = arrayCompleted.sort((a, b) => a.identify.localeCompare(b.identify))
        },
      ).finally(() => {
        this.isLoading = false
      })
    },

    async showFileInBrowser(idFromFile) {
      this.isLoadingFile = idFromFile

      const body = {
        id: idFromFile,
      }

      await axiosIns.post('api/v1/sgq/registration/view_files', body, { responseType: 'arraybuffer' }).then(
        response => {
          const file = new File([response.data], 'document.pdf', {
            type: 'application/pdf',
          })

          const urlFromFile = URL.createObjectURL(file)
          window.open(urlFromFile, '__blank')
        },
      ).catch(error => {
        this.$swal({
          title: 'Contate a equipe de desenvolvimento',
          text: error,
          icon: 'error',
        })
      }).finally(() => {
        this.isLoadingFile = false
      })
    },
  },

}
</script>
