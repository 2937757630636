import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VCard,[_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.filteredData,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.show_document",fn:function(ref){
var item = ref.item;
return [(item.files_id === _vm.isLoadingFile ? false : true )?_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"warning"},on:{"click":function($event){return _vm.showFileInBrowser(item.files_id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiEye)+" ")]):_c(VProgressCircular,{attrs:{"color":"warning","indeterminate":""}})]}}],null,true)}),_c(VCardText)],1)}
var staticRenderFns = []

export { render, staticRenderFns }