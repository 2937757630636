import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{class:{ 'dark-mode': _vm.isDark, 'light-mode': !_vm.isDark }},[_c(VCard,{staticClass:"ml-10 mr-10 px-5 py-5 d-flex justify-content-end"},[_c(VCol,{staticClass:"d-flex justify-content-end",attrs:{"cols":"12"}},[_c('div',{staticClass:"container-profile"},[_c(VIcon,{staticClass:"me-6",on:{"click":function($event){return _vm.$router.push({ path: '/' })}}},[_vm._v(" "+_vm._s(_vm.icons.mdiHome)+" ")]),_c('app-bar-theme-switcher'),_c('app-bar-user-menu')],1)])],1),_c(VCard,{staticClass:"ml-10 mr-10 mt-10",attrs:{"id":"account-setting-card"}},[_c(VTabs,{attrs:{"show-arrows":""},model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabItems),function(tab){return _c(VTab,{key:tab.title},[_c(VIcon,{staticClass:"me-3",attrs:{"size":"20"}},[_vm._v(" "+_vm._s(tab.icon)+" ")]),_c('span',[_vm._v(_vm._s(tab.title))])],1)}),1),_c('div',{staticClass:"d-flex align-end justify-end pr-5",staticStyle:{"width":"100%"}},[_c(VTextField,{staticClass:"mt-5",staticStyle:{"max-width":"300px"},attrs:{"append-icon":_vm.icons.mdiMagnify,"label":"Buscar por documentos","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c(VTabsItems,{model:{value:(_vm.tabs),callback:function ($$v) {_vm.tabs=$$v},expression:"tabs"}},_vm._l((_vm.tabItems),function(tab){return _c(VTabItem,{key:tab.title},[_c(tab.component,{tag:"component",attrs:{"search":_vm.search}})],1)}),1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }